// extracted by mini-css-extract-plugin
export var announcementCard = "home-module--announcementCard--kHpme";
export var announcementContent = "home-module--announcementContent--SpEJS";
export var announcementHeading = "home-module--announcementHeading--24roO";
export var announcementDate = "home-module--announcementDate--OKFL8";
export var bestChapterBanner = "home-module--bestChapterBanner--DI8CG";
export var joinCTAContainer = "home-module--joinCTAContainer--2xW3D";
export var joinCTAText = "home-module--joinCTAText--nPjcm";
export var ourSponsorsImage = "home-module--ourSponsorsImage--wJQyP";
export var ourSponsorsContactUs = "home-module--ourSponsorsContactUs--ZfFBt";
export var ourSponsorsHeader = "home-module--ourSponsorsHeader--BVUW0";
export var ourSponsorsImageContainer = "home-module--ourSponsorsImageContainer--OfPrB";