import React from "react"
import {
  event_heading,
  event_dates,
  event_desc,
  event_btn,
} from "../styles/event_details.module.css"
import { bestChapterBanner } from "../styles/home.module.css"

import { btn ,disabled} from "../styles/button.module.css"

// SVGs
// import svg1 from "../images/pulzion_bottom_vector.svg"
// import svg2 from "../images/pulzion_bottom_vector_2.svg"
// import svg3 from "../images/pulzion_bottom_vector_3.svg"

import AppButton from "./AppButton"
// import { navigate } from "gatsby"

const EventDetailsCard = props => {
  const { img, title, dates, body, link, 
    // webLink 
  } = props
  const isBrowser = typeof window !== "undefined"
  var disable_flag = false;
  if(link==="Nil"){
    disable_flag = true;
  }
  return (
    <div className="grid grid-cols-2 flex flex-col-reverse lg:flex-row px-4 sm:px-8 md:px-12 lg:px-16 py-8 lg:py-16 gap-16 items-center">
      <div className="col-span-2 lg:col-span-1">
        <h4 className={`${event_heading}`}>{title}</h4>
        <p className={`${event_dates} mt-4`}>{dates}</p>
        <div className="flex lg:hidden my-8">
          <img src={img} alt="banner" className={`${bestChapterBanner} rounded-md`} />
        </div>
        <p className={`${event_desc} text-justify my-8`}>{body}</p>
        <div
          className={`flex md:inline-flex flex-col md:flex-row items-center md:items-left`}
        >
          
          <AppButton
            title="visit website"
            icon="public"
            variant="secondary"
            className={`md:mr-8 ${btn} ${event_btn} ${disable_flag? disabled : " "}`}
            
            onClick={() => {
              if (isBrowser && !disable_flag) {
                window?.open(link)
              }
            }}
          />
          {/* <AppButton
            title="Visit gallery"
            icon="collections"
            variant="secondary"
            className={`mt-4 md:mt-0 ${btn} ${event_btn}`}
            onClick={() => navigate(webLink)}
          /> */}
        </div>
      </div>
      <div className="hidden lg:flex items-center h-100 col-span-2 lg:col-span-1">
        <img src={img} alt="banner" className={`${bestChapterBanner} rounded-md`} />
      </div>
      {/* <div>
        <img src={index % 3 === 0 ? svg1 : index % 3 === 1 ? svg2 : svg3} alt="dummy" className="w-full hidden md:block"></img>
      </div> */}
    </div>
  )
}

export { EventDetailsCard }
