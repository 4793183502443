import React from "react"
import {Helmet} from "react-helmet";

import { EventDetailsCard } from "../components/EventDetailsCard"
import pulzion21Img from "../images/pulzion21.png"
import pulzion19Img from "../images/pulzion19.png"
import pulzion18Img from "../images/pulzion18.png"
import pulzion22Img from "../images/pulzion22.jpg"
import pulzion23Img from "../images/pulzion23.png"
import pulzionTechImg from "../images/pulzionTechTreat.png"
import Banner from "../components/Banner"
import Layout from "../components/Layout"
import {
  domain_svg_middle,
  domain_svg_top,
} from "../styles/domaincard.module.css"

const eventData = [
  {
    img: pulzionTechImg,
    title: "Pulzion Tech or Treat",
    dates: "27, 28, 29 October, 2023",
    link: "https://pulzion.co.in/",
    webLink: "/gallery#pulzion",
    body: "Pulzion Tech or Treat, held from October 27th to 29th, 2023, marked the return of our flagship event . This provided a unique platform for students to engage in a spectrum of Tech and Non-Tech events, delving into various Computer Science domains while embracing the Halloween-themed atmosphere. The opening ceremony, graced by dignitaries Mr. Hemant Selmokar (Delivery Manager Infosys), Mr. Jayesh Bhangdiya (Co-founder Increment Jobs), and Dr. S.T. Gandhe, set the stage for a series of more than 10 events that garnered tremendous enthusiasm from numerous participants. Notable expert session, featuring Mr. Atharv Chavan, gave a lot of useful insights to the attendees. The event concluded with a memorable closing ceremony attended by Mr. Rohan Mote(Co-founder GrowthfAi) and Ms. Sushma Joshi (AnitaB.org Community Lead), culminating in the acknowledgment and awarding of event winners."
  },
  {
    img: pulzion23Img,
    title: "Pulzion '23",
    dates: "3, 4, 5 May, 2023",
    link: "Nil",
    webLink: "/gallery#pulzion",
    body: "Pulzion 23 unfolded from May 3rd to 5th, 2023, featuring a diverse array of 14 Technical and Non-technical Events. The opening ceremony was graced by Mr. Swapnil Avhad( AVP Deutsche Bank). With a  turnout of 3000+ participants engaging in various events, the event hosted a notable APIs 101 workshop by Postman and welcomed guest speaker Arsh Goyal. The closing ceremony, graced by dignitaries Mr. Sunil Uttamchandrani (Co-founder Mithi Software Technologies) and Dr. Sachin Lodha (Chief Scientist TCS), culminated in a prize ceremony honoring the winners across all events, marking a successful conclusion to the event."
  },
  {
    img: pulzion22Img,
    title: "Pulzion '22",
    dates: "22, 23 & 24 April, 2022",
    link: "Nil",
    webLink: "/gallery#pulzion",
    body: "This year, the annual fest of PICT ACM Student Chapter (PASC), Pulzion’22, was organized on the 22nd, 23rd, and 24th of April 2022. The entire event was conducted physically, offering a plethora of events and contests that catered to almost all possible interests of the students. A unique amalgam of more than ten events, Pulzion'22 catered to a large audience, giving each student a platform to showcase their talents. There was a colossal prize pool of Rs. 3 Lakhs. The event was graced by the presence of various dignitaries. Mr. Swapnil Avhad (AVP, Deutsche Bank) graced the event with their glorious present for the opening ceremony. On the 24th, the event concluded with the grand closing ceremony. The dignitaries of the ceremony were Mr. Raj Vikramaditya (SDE, Google) and Mr. Yogesh Daga (CEO, Nirmitee.io) as our Chief Guest. The dignitaries and PASC counsellor Dr. Geetanjali Kale announced the winners of all events in Pulzion'22.",
  },
  {
    img: pulzion21Img,
    title: "Pulzion '21",
    dates: "5, 6 & 7 March, 2021",
    link: "https://pict.acm.org/pulzion21/",
    webLink: "/gallery#pulzion",
    body: "Pulzion’21 was held on the 5th, 6th, and 7th of March 2021. Despite the event being in an online fashion, this year observed a multitude of students participating on a global level. Ingenious and compelling events such as Just coding, Recode it, Dataquest, Electroquest, Bug-off, Codelicious, Insight, Dextrous, Poster presentation, Photoshop Royale, Fandom quizzes profoundly engaged the participants, thus enhancing their skills in not only technical but also non-technical domains. The event was held globally to promote and encourage students of diverse backgrounds.",
  },
  {
    img: pulzion19Img,
    title: "Pulzion '19",
    dates: "23, 24 & 25 August, 2019",
    link: "https://pict.acm.org/pulzion19/",
    webLink: "/gallery#pulzion19",
    body: "Pulzion’19 was organized on 23, 24, and 25 August 2019. The event was sponsored by Capgemini. It was a remarkable success, with a footfall of over 5000, attracting students from all over Maharashtra. The theme for the year was 'Sci-Fi'. Various events like Just Coding, Recode it, Code Buddy, Dexterous, Insight, Electroquest rigorously engaged the participants, thus pushing them to the very limit of their ability.",
  },
  {
    img: pulzion18Img,
    title: "Pulzion '18",
    dates: "17, 18 & 19 August, 2019",
    link: "https://pict.acm.org/pulzion-18/Home/",
    webLink: "/gallery#pulzion18",
    body: "Pulzion'18 was sponsored by Persistent in which over 3300 students competed in various events held in different domains, followed by active involvement in thought-provoking workshops and seminars. These competitions were well-received by competitive coders, networking lovers, circuit designers, creative writers, photoshop artists, cricket enthusiasts, TV Series fans, and business minds.",
  },
]

const Pulzion = () => {
  return (
    <Layout>
      <Helmet>
          <meta charSet="utf-8" />
          <title>PICT ACM Student Chapter | Pulzion</title>
          <meta name="description" content="PICT ACM Student Chapter(PASC), a student chapter organization subsidiary of the Association for Computing Machinery(ACM), consists of highly motivated students, ready to learn and help each other bring the best out of them. PASC began in 2011, with the perspective of fostering technical and non-technical qualities in an individual and helping them to shape their future." />
      </Helmet>
      <Banner
        title="Pulzion"
        image="pulzion.png"
        text="Pulzion is the annual flagship event conducted by PASC. It consists of various innovative and exhilarating sub-events which help the participants enhance their skills. It has been eight years since we started organizing this annual event, and each year has seen exponential growth with immense participation bringing out a plethora of ideas."
      />
      <div className="py-8 lg:py-16 relative">
        <img
          src="/bg_svg_2.svg"
          className={`${domain_svg_top} hidden lg:block`}
          alt="svg"
        />
        <img
          src="/bg_svg_1.svg"
          className={`${domain_svg_middle} hidden lg:block`}
          style={{
            width: "250px"
          }}
          alt="svg"
        />
        {eventData.map((event, index) => (
          <EventDetailsCard
            img={event.img}
            title={event.title}
            dates={event.dates}
            body={event.body}
            index={index}
            link={event.link}
            webLink={event.webLink}
          />
        ))}
      </div>
    </Layout>
  )
}

export default Pulzion
